import sidBarSliceMenu from "./Slice/SideBarSlice";
import caseManagemenSliceMenu from "./Slice/CaseManagementMenuSlice";
import { combineReducers } from "redux";
import AgeingTabSlice from "./Slice/AgeingTabSlice";
import FilterSlice from "./Slice/FilterSlice";
import loginSlice from "./Slice/loginSlice";
import SearchSlice from "./Slice/SearchSlice";
import documentSlice from "./Slice/documentSlice";
import CaseDescriptionSlice from "./Slice/CaseManagementActionsSlice/CaseDescriptionSlice";
import approvalVendorSlice from "./Slice/ApprovalSlice/approvalVendorSlice";
import appTourSlice from "./Slice/appTourSlice";
import caseSearchSlice from "./Slice/CaseSearchSlice/caseSearchSlice";

const reducers = combineReducers({
  sidBarSliceMenu,
  caseManagemenSliceMenu,
  AgeingTabSlice,
  FilterSlice,
  loginSlice,
  documentSlice,
  SearchSlice,
  CaseDescriptionSlice,
  approvalVendorSlice,
  appTourSlice,
  caseSearchSlice,
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};
