import React, { useState, useEffect } from "react";
import SideBarElements from "./SideBarElements";
import Box from "@mui/material/Box";
import classes from "./SideBar.module.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { tabReducer } from "../../Redux/Slice/SideBarSlice.jsx";
import { imageBaseURL } from "../../constants/utils.js";

const SideBar = () => {
  const [currentRoute, setCurrentRoute] = useState("/home");
  const tab = useSelector((state) => state.sidBarSliceMenu.sideBarMenu);
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeTourStep } = useSelector((state) => state.appTourSlice);
  const isTourActive =
    activeTourStep === 2
      ? {
          background: "white",
          boxShadow: "0px 2px 10px 0px #FFFFFFB2",
          borderRadius: "20px",
          border: "1px solid #9F9F9F",
        }
      : {};
  const isTourActive1 =
    activeTourStep === 3
      ? {
          zIndex: 1000,
        }
      : {};

  useEffect(() => {
    setCurrentRoute(location?.pathname);
  });

  return (
    <Box
      id={"sidebar-menu"}
      sx={{
        width: { md: "7%", sm: "12%" },
        height: { md: "83%", sm: "auto" },
        "& .MuiBox-root": { height: "83%" },
        position: "fixed",
        mt: "2rem",
        ...isTourActive,
        ...isTourActive1,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "inline-flex", md: "inline-flex" },
          flexDirection: "column",
          textDecoration: "none",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "2rem 1.4rem",
          background: "rgba(228, 33, 37, 0.05)",
          borderRadius: "1.6rem",
          width: { md: "7%", sm: "12%" },
          height: { md: "80%", sm: "auto" },
          gap: "1.4rem",
          "& .MuiBox-root": { height: "83%" },
          position: "fixed",
        }}
      >
        <Link to="/home" className={classes["sideBar-icon"]} id="sidebar-home">
          <SideBarElements
            id="sidebar-home"
            sideBarIcon={
              currentRoute === "/home"
                ? activeTourStep > 1
                  ? `${imageBaseURL}/Svg/Home1.svg`
                  : `${imageBaseURL}/Svg/Home-red.svg`
                : `${imageBaseURL}/Svg/Home1.svg`
            }
            sideBarlabel="Home"
            labelColor={
              currentRoute === "/home"
                ? activeTourStep > 1
                  ? "#3A3A3A"
                  : "#E94D51"
                : "#3A3A3A"
            }
            fontWeight={currentRoute === "/home" ? 700 : 400}
            onClick={() => dispatch(tabReducer("homeTab"))}
          />
        </Link>
        <Link
          to="/caseManagement"
          className={
            classes[
              `${activeTourStep === 3 ? "sideBar-icon-tour" : "sideBar-icon"}`
            ]
          }
          id="sidebar-case-management"
        >
          <SideBarElements
            // id="sidebar-case-management"
            sideBarIcon={
              currentRoute == "/caseManagement"
                ? `${imageBaseURL}/Svg/case-management-filled.svg`
                : `${imageBaseURL}/Svg/case-management.svg`
            }
            sideBarlabel="Case Management"
            labelColor={
              currentRoute == "/caseManagement" ? "#E94D51" : "#3A3A3A"
            }
            fontWeight={currentRoute === "/caseManagement" ? 700 : 400}
            onClick={() => dispatch(tabReducer("CaseManagementTab"))}
          />
        </Link>
        <Link
          to="/vendorManagement"
          className={
            classes[
              `${activeTourStep === 4 ? "sideBar-icon-tour" : "sideBar-icon"}`
            ]
          }
          id="sidebar-vendor-management"
        >
          <SideBarElements
            // id="sidebar-vendor-management"
            sideBarIcon={
              currentRoute === "/vendorManagement"
                ? `${imageBaseURL}/Svg/vendor-management-filled.svg`
                : `${imageBaseURL}/Svg/vendor-management.svg`
            }
            sideBarlabel="Vendor Management"
            labelColor={
              currentRoute === "/vendorManagement" ? "#E94D51" : "#3A3A3A"
            }
            fontWeight={currentRoute === "/vendorManagement" ? 700 : 400}
            onClick={() => dispatch(tabReducer("VendorManagementTab"))}
          />
        </Link>
        <Link
          to="reports"
          className={
            classes[
              `${activeTourStep === 5 ? "sideBar-icon-tour" : "sideBar-icon"}`
            ]
          }
          id="sidebar-reports"
        >
          <SideBarElements
            // id="sidebar-reports"
            sideBarIcon={
              currentRoute === "/reports"
                ? `${imageBaseURL}/Svg/mis-reports-filled.svg`
                : `${imageBaseURL}/Svg/mis-reports.svg`
            }
            sideBarlabel="MIS  &  Reports"
            labelColor={currentRoute === "/reports" ? "#E94D51" : "#3A3A3A"}
            fontWeight={currentRoute === "/reports" ? 700 : 400}
            onClick={() => dispatch(tabReducer("Mis&Reports"))}
          />
        </Link>
        <Link
          to="approval"
          className={
            classes[
              `${activeTourStep === 6 ? "sideBar-icon-tour" : "sideBar-icon"}`
            ]
          }
          id="sidebar-approval"
        >
          <SideBarElements
            // id="sidebar-approval"
            sideBarIcon={
              currentRoute === "/approval"
                ? `${imageBaseURL}/Svg/approve-filled.svg`
                : `${imageBaseURL}/Svg/Approve.svg`
            }
            sideBarlabel="Approval"
            labelColor={currentRoute === "/approval" ? "#E94D51" : "#3A3A3A"}
            fontWeight={currentRoute === "/approval" ? 700 : 400}
            onClick={() => dispatch(tabReducer("Approval"))}
          />
        </Link>
        <Link
          to="invoice"
          className={
            classes[
              `${activeTourStep === 7 ? "sideBar-icon-tour" : "sideBar-icon"}`
            ]
          }
          id="sidebar-invoice"
        >
          <SideBarElements
            // id="sidebar-invoice"
            sideBarIcon={
              currentRoute === "/invoice"
                ? `${imageBaseURL}/Svg/invoice-filled.svg`
                : `${imageBaseURL}/Svg/invoice.svg`
            }
            sideBarlabel="Invoice"
            labelColor={currentRoute === "/invoice" ? "#E94D51" : "#3A3A3A"}
            fontWeight={currentRoute === "/invoice" ? 700 : 400}
            onClick={() => dispatch(tabReducer("Invoice"))}
          />
        </Link>
        <Link to="audit" className={classes["sideBar-icon"]}>
          <SideBarElements
            id="sidebar-audit"
            sideBarIcon={
              currentRoute === "/audit"
                ? `${imageBaseURL}/Svg/Audit-filled.svg`
                : `${imageBaseURL}/Svg/audit.svg`
            }
            sideBarlabel="Audit"
            labelColor={currentRoute === "/audit" ? "#E94D51" : "#3A3A3A"}
            fontWeight={currentRoute === "/audit" ? 700 : 400}
            onClick={() => dispatch(tabReducer("Audit"))}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default SideBar;
